import React, { createContext, useState, useEffect, useContext, Dispatch } from "react";
import { AlertContext } from "../../Contexts/AlertContext/AlertContext";
import { useAPI } from "../../Hooks/useAPI/useAPI";
import { Environments } from "../../Environments/Environments";
import { useSocket } from "../../Hooks/useSocket/useSocket";
import { useRefState } from "../../Hooks/useRefState/useRefState";
import { useLocalStorage } from "../../Hooks/useLocalStorage/useLocalStorage";
import { GlobalContext } from "../GlobalContext/GlobalContext";

export const NotificationContext =
  createContext(undefined);

export const NotificationProvider = ({ children }) => {
  const { showAlert } = useContext(AlertContext);
  const { token, thisUser } = useContext(GlobalContext);

  const { getItem, setItem } = useLocalStorage();

  const [notifications, notificationsRef, setNotifications] = useRefState([]);
  const [notificationCount, notificationCountRef, setNotificationCount] = useRefState(0);
  const [notificationToCheck, setNotificationToCheck] = useState(false);
  const [activeNotifications, setActiveNotifications] = useState(false);

  const [ pushNotificationOption, setPushNotificationOption ] = useState(Boolean(getItem('ibisaPushNotification') ?? true));
  const [ notificationSoundOption, setNotificationSoundOption ] = useState(Boolean(getItem('ibisaNotificationSound') ?? true));

  const socket = useSocket(Environments.sockets.notifications, { auth: { token } });

  const checkNotifications = () => {
    if (!("Notification" in window)) {
      showAlert({
        type: "warning",
        title: "Notificaciones no disponibles",
        message: "El navegador no permite notificaciones",
      });
      return;
    }
    if (Notification.permission === "granted") {
      setActiveNotifications(true);
    } else {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            setActiveNotifications(true);
          } else {
            setActiveNotifications(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const initialNotifications = useAPI(Environments.toolbox + "notifications?limit=25");

  const checkedNotification = useAPI(
    {
      url: Environments.toolbox + "notifications/" + notificationToCheck,
      showAlert: true,
    },
    "PUT",
    { seen: true },
    [notificationToCheck],
    notificationToCheck
  );

  useEffect(() => {
    setItem('ibisaNotificationSound', notificationSoundOption);
  }, [ notificationSoundOption ]);

  useEffect(() => {
    setItem('ibisaPushNotification', pushNotificationOption);
  }, [ pushNotificationOption ]);
  
  useEffect(() => {
    if (!socket.connected) return;
    
    socket.addListener('notification-new', (notification) => {
      if (
        thisUser.authId !== notification?.to &&
        !thisUser.function?.some(f => f === notification?.to)
      ) return;

      setNotifications([ notification, ...notificationsRef.current ]);
      setNotificationCount(notificationCountRef.current + 1);
    });

    socket.addListener('notification-update', (notification) => {
      let newNotifications = [ ...notificationsRef.current ];

      newNotifications.splice(newNotifications.findIndex(n => n._id === notification._id), 1, notification);

      setNotifications(newNotifications);
      setNotificationCount(newNotifications.filter((n) => !n.seen).length);
    });

    socket.addListener('notification-delete', (notificationId) => {
      let newNotifications = [ ...notificationsRef.current ];

      newNotifications.splice(newNotifications.findIndex(n => n._id === notificationId), 1);

      setNotifications(newNotifications);
      setNotificationCount(newNotifications.filter((n) => !n.seen).length);
    });
  }, [ socket.connected ]);

  useEffect(() => {
    if (!initialNotifications.loading && !initialNotifications.error) {
      let newNotifications = [ ...initialNotifications.response.data ];

      setNotifications(newNotifications);
      setNotificationCount(newNotifications.filter((n) => !n.seen).length);
    }
  }, [initialNotifications.loading]);

  useEffect(() => {
    checkNotifications();
  }, []);

  useEffect(() => {
    if (!checkedNotification.loading && !checkedNotification.error) {
      setNotificationCount(notificationCountRef.current);
      setNotificationToCheck(false);
    } else {
      setNotificationToCheck(false);
    }
  }, [ checkedNotification.loading ]);

  const values = {
    notificationCount,
    notifications,
    setNotificationToCheck,
    initialNotifications,
    notificationSoundOption,
    setNotificationSoundOption,
    pushNotificationOption,
    setPushNotificationOption,
    activeNotifications
  };

  return (
    <NotificationContext.Provider value={values}>
      {children}
    </NotificationContext.Provider>
  );
};
