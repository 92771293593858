import { filterObject } from "../../Utils/filterObject";
import "./IbisaButton.css";

export const colors = [
  'green',
  'blue',
  'red',
  'purple',
  'black',
  'yellow',
  'orange',
  'gray'
];

const sizes = [ 'small', 'medium', 'big' ];

const colorModes = [ 'transparent', 'inverted' ];

const buttonTypes = [ 'circle' ];

export const IbisaButton = (props) => {

  const { color, size, colorMode, buttonType } = props;
  const buttonProps = { ...filterObject(props, (_, key) => key !== 'colorMode' && key !== 'buttonType'), type: props.type ?? "button" };

  let className = "ibisa-button";

  if (colors.includes(color)) className += ` btn-${color}`;
  else className += " btn-black";

  if (sizes.includes(size)) className += ` btn-${size}`;
  else className += " btn-medium";

  if (colorModes.includes(colorMode)) className += ` btn-${colorMode}`;

  if (buttonTypes.includes(buttonType)) className += ` btn-${buttonType}`;

  if (props?.className) className += ` ${props.className}`;

  return <button { ...buttonProps } className={className} />
}