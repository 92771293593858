export const useLocalStorage = () => {
  const getItem = (name, decode = true) => {
    try {   
      let val = localStorage.getItem(name);
  
      if (!val) return val;
      if (decode) val = atob(val);
      
      return JSON.parse(val);
    } catch (error) {
      console.error(error);
    }
  }

  const setItem = (name, value, encode = true) => {
    try {
      let val = JSON.stringify(value);
      
      if (encode) val = btoa(val);
  
      localStorage.removeItem(name);
      localStorage.setItem(name, val);
    } catch (error) {
      console.error(error);
    }
  }

  const deleteItem = (name) => localStorage.removeItem(name);

  return {
    getItem,
    setItem,
    deleteItem
  }
}