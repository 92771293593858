import React, { createContext, useContext, useEffect, useState } from 'react';
import { HeaderUI } from './HeaderUI';
import { Sidebar } from './Sidebar/Sidebar';
import { faAngleDoubleUp, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from "@auth0/auth0-react";
import { GlobalContext } from '../../Contexts/GlobalContext/GlobalContext'
import { useLocation } from "react-router-dom";
import { useAPI } from 'src/Hooks/useAPI/useAPI';
import { Environments } from 'src/Environments/Environments';
import { LanguageContext } from 'src/Components/languageContext/LanguageContext';
import { NotificationAlert } from '../NotificationAlert/NotificationAlert';
import { useRefState } from 'src/Hooks/useRefState/useRefState';
import { useSocket } from 'src/Hooks/useSocket/useSocket';
import { NotificationContext } from 'src/Contexts/NotificationContext/NotificationContext';

const HeaderContext = createContext();

function HeaderProvider ({children}) {

  const [clickedElement, setClickedElement] = useState(<span id="48753489578387fyhudhsfh"></span>);

  window.onclick = e => {
    setClickedElement(e.target);
  }

  return (
    <HeaderContext.Provider
      value={{
        clickedElement,
        setClickedElement
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

function Header() {

  const location = useLocation();

  const { thisUserFunctions, toggleTheme, darkTheme, token, thisUser } = useContext(GlobalContext);
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);
  const { notifications, notificationCount, setNotificationToCheck, notificationSoundOption, setNotificationSoundOption, pushNotificationOption, setPushNotificationOption } = useContext(NotificationContext);

  const { isLoading, user, } = useAuth0();
  
  const [ sidebarDeploy, setSidebarDeploy ] = useState(false);
  const [ appsOptionsIcon, setAppsOptionsIcon] = useState(faAngleDoubleDown);
  const [ loggedUser, setLoggedUser ] = useState({});
  const [ displayOptions, toggleOptions ] = useState(false);

  const HeaderAppsOptionToggleColor = `ibisa-apps-option ${(appsOptionsIcon === faAngleDoubleDown) ? "ibisa-apps-option-deployed":""}`;

  const deployProcessOptions = () => {
    document.getElementById("SidebarDropdownProcess").classList.toggle("sidebar-dropdown-deployed");
  }

  const deployAppsOptions = () => {
    document.getElementById("SidebarDropdownApps").classList.toggle("sidebar-dropdown-deployed");
    if (appsOptionsIcon === faAngleDoubleDown) {
      setAppsOptionsIcon(faAngleDoubleUp);
    } else {
      setAppsOptionsIcon(faAngleDoubleDown);
    }
  }

  useEffect(() => setSidebarDeploy(false), [location]);

  useEffect(() => { if (!isLoading) setLoggedUser(user) }, [isLoading]);

  return (
    <HeaderProvider>
      <Sidebar
        sidebarDeploy={sidebarDeploy}
        setSidebarDeploy={setSidebarDeploy}
        appsOptionsIcon={appsOptionsIcon}
        setAppsOptionsIcon={setAppsOptionsIcon}
        deployAppsOptions={deployAppsOptions}
        deployProcessOptions={deployProcessOptions}
        thisUserFunctions={thisUserFunctions}
        darkTheme={darkTheme}

        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
      <NotificationAlert
        notifications={notifications}
      />
      <HeaderUI
        sidebarDeploy={sidebarDeploy}
        setSidebarDeploy={setSidebarDeploy}
        appsOptionsIcon={appsOptionsIcon}
        setAppsOptionsIcon={setAppsOptionsIcon}
        notifications={notifications}
        deployAppsOptions={deployAppsOptions}
        deployProcessOptions={deployProcessOptions}
        HeaderAppsOptionToggleColor={HeaderAppsOptionToggleColor}
        user={loggedUser}
        thisUserFunctions={thisUserFunctions}
        toggleTheme={toggleTheme}
        darkTheme={darkTheme}
        setNotificationToCheck={setNotificationToCheck}
        notificationCount={notificationCount}
        // reditectToPaper={reditectToPaper}

        displayOptions={displayOptions}
        toggleOptions={toggleOptions}

        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}

        notificationSoundOption={notificationSoundOption}
        setNotificationSoundOption={setNotificationSoundOption}
        pushNotificationOption={pushNotificationOption}
        setPushNotificationOption={setPushNotificationOption}
      />
    </HeaderProvider>
  )
}

export { Header, HeaderContext };