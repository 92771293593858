import React, { useContext } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import "./Sidebar.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable, faProjectDiagram, faClipboardList, faChartPie, faFileAlt, faShapes, faBars, faAngleDoubleDown, faCoins, faUsers, faScroll, faCalculator, faPuzzlePiece, faCalendarAlt, faTasks, faNetworkWired, faSearch, faUserCog, faFolderOpen, faHandHoldingDollar, faTasksAlt, faChartLine, faServer, } from "@fortawesome/free-solid-svg-icons";
import { LanguageContext } from "src/Components/languageContext/LanguageContext";

function Sidebar({ sidebarDeploy, setSidebarDeploy, appsOptionsIcon, deployAppsOptions, deployProcessOptions, darkTheme, thisUserFunctions }) {

  const { t } = useContext(LanguageContext);

  return (
    <section id='my-sidebar' className={sidebarDeploy ? "sidebar-deploy-true" : "sidebar-deploy-false"}>
      <div className='sidebar-head'>
        <button className='sidebar-btn' onClick={() => setSidebarDeploy(!sidebarDeploy)}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        <Link to='/' className='sidebar-logo-ibisa-container'>
          <img src={`/img/${darkTheme ? "logoDark" : "logo"}.png`} alt='Logo Ibisa' />
        </Link>
      </div>
      <div className='sidebar-body'>
        <div className='sidebar-tools'>
          <div className='sidebar-search-container'>
            <input type='text' className='sidebar-input-search' placeholder='Buscar publicaciones, documentos y más' />
            <span className='sidebar-input-search-icon'>
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>
          <div id='SidebarDropdownProcess' className='process-options-container sidebar-dropdown' onClick={deployProcessOptions}>
            <div className={`sidebar-tool ${useLocation().pathname.includes("procesos") ? "sidebar-tool--selected" : ""}`}>
              <span className='sidebar-tool-icon'>
                <FontAwesomeIcon icon={faNetworkWired} />
              </span>
              <span className='sidebar-tool-text'>{t('words.procesos')}</span>
            </div>
            <div className='sidebar-dropdown-content'>
              <Link to='/procesos' className='sidebar-dropdown-item sidebar-process-item'>
                <span className='sidebar-process-item-icon'>
                  <FontAwesomeIcon icon={faTasks} />
                </span>
                <span className='sidebar-process-item-text'>{t('words.procesos')}</span>
              </Link>
              <Link to='/procesos/modelador' className='sidebar-dropdown-item sidebar-process-item'>
                <span className='sidebar-process-item-icon'>
                  <FontAwesomeIcon icon={faProjectDiagram} />
                </span>
                <span className='sidebar-process-item-text'>{t('words.modelador')}</span>
              </Link>
              <Link to='/procesos/guardados' className='sidebar-dropdown-item sidebar-process-item'>
                <span className='sidebar-process-item-icon'>
                  <FontAwesomeIcon icon={faUserCog} />
                </span>
                <span className='sidebar-process-item-text'>{t('header.misprocesos')}</span>
              </Link>
              <Link to='/procesos/desplegados' className='sidebar-dropdown-item sidebar-process-item'>
                <span className='sidebar-process-item-icon'>
                  <FontAwesomeIcon icon={faFolderOpen} />
                </span>
                <span className='sidebar-process-item-text'>
                {t('header.misdespliegues')}
                </span>
              </Link>
            </div>
          </div>
          <NavLink to='/tablas' className={({ isActive }) => `sidebar-tool ${isActive ? "sidebar-tool--selected" : ""}`}>
            <span className='sidebar-tool-icon'>
              <FontAwesomeIcon icon={faTable} />
            </span>
            <span className='sidebar-tool-text'>{t('words.tablas')}</span>
          </NavLink>
          <NavLink to='/tareas' className={({ isActive }) => `sidebar-tool ${isActive ? "sidebar-tool--selected" : ""}`}>
            <span className='sidebar-tool-icon'>
              <FontAwesomeIcon icon={faClipboardList} />
            </span>
            <span className='sidebar-tool-text'>{t('words.tareas')}</span>
          </NavLink>
          <NavLink to='/reportes' className={({ isActive }) => `sidebar-tool ${isActive ? "sidebar-tool--selected" : ""}`}>
            <span className='sidebar-tool-icon'>
              <FontAwesomeIcon icon={faChartPie} />
            </span>
            <span className='sidebar-tool-text'>{t('words.reportes')}</span>
          </NavLink>
          <NavLink to='forms' className={({ isActive }) => `sidebar-tool ${isActive ? "sidebar-tool--selected" : ""}`}>
            <span className='sidebar-tool-icon'>
              <FontAwesomeIcon icon={faScroll} />
            </span>
            <span className='sidebar-tool-text'>{t('words.formularios')}</span>
          </NavLink>
        </div>
        <div
          id='SidebarDropdownApps'
          className={`apps-sidebar-option sidebar-dropdown`}
          onClick={deployAppsOptions}>
          <div className='apps-deploy-button'>
            <span className='apps-icon-left'>
              <FontAwesomeIcon icon={faShapes} />
            </span>
            <span className='apps-text'>Apps</span>
            <span className='apps-icon-right'>
              <FontAwesomeIcon icon={appsOptionsIcon} />
            </span>
          </div>
          <div className={`apps-options-container sidebar-dropdown-content`}>
            <Link to='apps/links' className='app-sidebar'>
              <FontAwesomeIcon icon={faServer} className='app-sidebar-icon' />
              <span className='app-sidebar-text'>{t('words.enlaces')}</span>
            </Link>
            <Link to='/apps/things' className='app-sidebar'>
              <FontAwesomeIcon icon={faCoins} className='app-sidebar-icon' />
              <span className='app-sidebar-text'>{t('words.activos')}</span>
            </Link>
            <Link to='apps/users' className='app-sidebar'>
              <FontAwesomeIcon icon={faUsers} className='app-sidebar-icon' />
              <span className='app-sidebar-text'>{t('words.personas')}</span>
            </Link>
            {/* <Link to='apps/newton' className='app-sidebar'>
              <FontAwesomeIcon icon={faCalculator} className='app-sidebar-icon' />
              <span className='app-sidebar-text'>{t('words.calculos')}</span>
            </Link>
            <Link to='apps/integrator' className='app-sidebar'>
              <FontAwesomeIcon icon={faPuzzlePiece} className='app-sidebar-icon' />
              <span className='app-sidebar-text'>{t('words.integraciones')}</span>
            </Link>
            <Link to='apps/streams' className='app-sidebar'>
              <FontAwesomeIcon icon={faCalendarAlt} className='app-sidebar-icon' />
              <span className='app-sidebar-text'>{t('words.eventos')}</span>
            </Link> */}
            <Link to='/apps/archives' className='app-sidebar'>
              <FontAwesomeIcon icon={faFolderOpen} className='app-sidebar-icon' />
              <span className='app-sidebar-text'>{t('words.archivos')}</span>
            </Link>
            {thisUserFunctions.includes("superuser") && (
              <Link to='apps/reassign-tasks' className='app-sidebar'>
                <FontAwesomeIcon icon={faTasksAlt} className='app-sidebar-icon' />
                <span className='app-sidebar-text'>{t('header.reasignartareas')}</span>
              </Link>
            )}
            {/* <Link to='apps/creditos' className='app-sidebar'>
              <FontAwesomeIcon icon={faHandHoldingDollar} className='app-sidebar-icon' />
              <span className='app-sidebar-text'>{t('words.creditos')}</span>
            </Link> */}
            <a href='https://paper.ibisagroup.com:3000' target='_blank' className='app-sidebar'>
              <FontAwesomeIcon icon={faChartLine} className='app-sidebar-icon' />
              <span className='app-sidebar-text'>{t('header.tablerosdecontrol')}</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export { Sidebar };
