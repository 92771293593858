import React, { useEffect, useRef } from 'react';
import "./Modal.css";

function Modal({ children, display, toggle, onClose }) {

  const isFirtsRender = useRef(true);

  useEffect(() => {
    if (!display && typeof onClose === 'function' && !isFirtsRender.current) onClose();
    isFirtsRender.current = false;
  }, [display])

  return (
    <div className={`modal-container ${(display) ? "modal-true" : "modal-false"}`} >
      <div className="modal-background" onClick={() => toggle(false)}></div>
      <div className="modal-content">
        {children}
      </div>
    </div>
  )
}

export { Modal }