import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { langs } from './langs';

i18next.use(LanguageDetector).use(initReactI18next).init({
  resources: langs,
  fallbackLng: "es",
  interpolation: { escapeValue: false }
})